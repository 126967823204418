import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import Item from './Item'
import { Section, Container } from 'components/layout'
import { Row, Column12 } from 'components/ui/Grid'
import { BodyCopy } from 'components/ui/Typography'

import s from './AwardsSection.module.scss'
const cn = classNames.bind(s)

const AwardsSection = ({ title, data }) => {
  const layoutClass = `by${data.length % 3 === 0 ? 3 : 2}`

  return (
    <Section>
      <Container>
        <Row>
          <Column12 initial={10} medium={10} desktop={8} center>
            {title && (
              <BodyCopy as="h3" className={cn('title')}>
                {title}
              </BodyCopy>
            )}
            <div className={cn('wrapper', layoutClass)}>
              {data.map((item, i) => (
                <Item key={i} logo={item.logo} info={item.info} total={item.total} />
              ))}
            </div>
          </Column12>
        </Row>
      </Container>
    </Section>
  )
}

AwardsSection.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
}

export default AwardsSection
