import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import { Subtitle, BodyCopy } from 'components/ui/Typography'
import ParallaxImage from 'components/motion/ParallaxImage'
import ViewportEnter from 'components/motion/ViewportEnter'

import s from './TestimonialsSection.module.scss'
const cn = classNames.bind(s)

const Item = ({ image, quote, author }) => {
  const [isInView, setIsInView] = useState(false)

  return (
    <div className={cn('item')}>
      <ViewportEnter onEnter={() => setIsInView(true)}>
        <div className={cn('image', { isInView })}>
          <div className={cn('masks')}>
            <span className={cn('mask')} />
            <span className={cn('mask')} />
          </div>
          <div className={cn('outer')}>
            <div className={cn('inner')}>
              <div className={cn('scaler')}>
                <ParallaxImage image={image} alt={image?.alt || author} />
              </div>
            </div>
          </div>
        </div>
      </ViewportEnter>
      <div className={cn('content')}>
        <div className={cn('quote')}>
          <BodyCopy>{quote}</BodyCopy>
        </div>
        <div className={cn('author')}>
          <Subtitle>{author}</Subtitle>
        </div>
      </div>
    </div>
  )
}

Item.propTypes = {
  image: PropTypes.object,
  quote: PropTypes.string,
  author: PropTypes.string,
}

export default Item
