import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import Circle from 'assets/svg/shapes-circle.inline.svg'
import Square from 'assets/svg/shapes-square.inline.svg'

import s from './OurPerspectivesSection.module.scss'
const cn = classNames.bind(s)

const Item = ({ renderAs, animationClass }) => {
  const Component = renderAs
  return (
    <div className={cn('item', animationClass)}>
      <Component />
      {animationClass === 'shapes' && (
        <div className={cn('aux')}>
          <Circle />
          <Square />
        </div>
      )}
    </div>
  )
}

Item.propTypes = {
  renderAs: PropTypes.func,
  animationClass: PropTypes.string,
}

export default Item
