import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import Item from './Item'
import { Section, Container } from 'components/layout'
import { Row, Column12 } from 'components/ui/Grid'

import s from './TestimonialsSection.module.scss'
const cn = classNames.bind(s)

const TestimonialsSection = ({ data }) => {
  return (
    <Section>
      <Container>
        <Row>
          <Column12 initial={10} medium={10} desktop={8} center>
            <div className={cn('wrapper')}>
              {data.map((item, i) => (
                <Item key={i} image={item.imageSharp} quote={item.quote} author={item.author} />
              ))}
            </div>
          </Column12>
        </Row>
      </Container>
    </Section>
  )
}

TestimonialsSection.propTypes = {
  data: PropTypes.array,
}

export default TestimonialsSection
