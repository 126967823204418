import { graphql } from 'gatsby'

import AboutPage from 'views/about/AboutPage'

export const query = graphql`
  query AboutPageQuery {
    prismic {
      page: about_page(uid: "about", lang: "en-us") {
        page_meta_title
        page_meta_description
        page_meta_thumbnail
        about_page_title
        body {
          ... on PRISMIC_About_pageBodyInfo_section {
            type
            primary {
              title
              subtitle
              content
              link {
                ...PrismicExtLinkFrag
                ...PrismicDocLinkFrag
              }
              link_text
            }
          }
          ... on PRISMIC_About_pageBodyImage_grid {
            type
            label
            fields {
              text
              title
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on PRISMIC_About_pageBodyTestimonials {
            type
            label
            fields {
              quote
              author
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on PRISMIC_About_pageBodyAwards {
            type
            label
            primary {
              section_title
            }
            fields {
              total
              info
              logo
            }
          }
          ... on PRISMIC_About_pageBodyTeam {
            type
            label
            primary {
              team_cta
              team_link {
                ...PrismicExtLinkFrag
                ...PrismicDocLinkFrag
              }
            }
            fields {
              name
              position
              brief
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

AboutPage.query = query
// AboutPage.fragments = [ProjectCardFrag]

export default AboutPage
