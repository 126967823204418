import React from 'react'

import Layout from 'layouts'

import Spacer from 'components/ui/Spacer'
import VerticalScroll from 'components/layout/VerticalScroll'
import InfoSection from 'components/ui/InfoSection'
import ImageGridSection from './components/ImageGridSection'
import TestimonialsSection from './components/TestimonialsSection'
import TeamSection from './components/TeamSection'
import AwardsSection from './components/AwardsSection'
import OurPerspectivesSection from './components/OurPerspectivesSection'
import SEO from 'components/seo'

const AboutPage = ({ data: { prismic }, ...props }) => {
  const { page } = prismic
  const metaTitle = page?.page_meta_title
  const metaDescription = page?.page_meta_description
  const metaThumbnail = page?.page_meta_thumbnail

  const infoSectionsData = page?.body?.filter((i) => i.type === 'info_section') || {}
  const imageGridData = page?.body?.find((i) => i.type === 'image_grid') || {}
  const testimonialsData = page?.body?.find((i) => i.type === 'testimonials') || {}
  const awardsData = page?.body?.find((i) => i.type === 'awards') || {}
  const teamData = page?.body?.find((i) => i.type === 'team') || {}

  return (
    <Layout lightTheme hasFooterThemeWaypoint={false}>
      <SEO title={metaTitle} description={metaDescription} thumbnail={metaThumbnail} lightTheme />
      <VerticalScroll>
        <Spacer initial="8.6255rem" desktop="17vw" />
        <InfoSection data={infoSectionsData[0] || {}} />
        <Spacer />
        <ImageGridSection data={imageGridData?.fields} />
        <Spacer />
        <InfoSection data={infoSectionsData[1] || {}} />
        <OurPerspectivesSection />
        <TestimonialsSection data={testimonialsData?.fields} />
        <Spacer medium="10vw" desktop="20vw" large="30vw" />
        <InfoSection data={infoSectionsData[2] || {}} hideOnMobile waypoint="dark" />
        <Spacer initial="0" medium="13.8vw" />
        <TeamSection data={teamData?.fields} cta={teamData?.primary || {}} />
        <Spacer />
        <AwardsSection data={awardsData?.fields} title={awardsData?.primary.section_title[0].text || ''} />
        <Spacer medium="0" />
      </VerticalScroll>
    </Layout>
  )
}

export default AboutPage
