import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import { Headline1, BodyCopy } from 'components/ui/Typography'
import Image from 'components/ui/Image'
import ViewportEnter from 'components/motion/ViewportEnter'

import s from './AwardsSection.module.scss'
const cn = classNames.bind(s)

const Item = ({ logo, total, info }) => {
  const [isInView, setIsInView] = useState(false)

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <div className={cn('item', { isInView })}>
        <div className={cn('total')}>
          <div className={cn('logo')}>
            <Image alt={logo?.alt || info} src={logo} />
          </div>
          <Headline1 as="h4">
            <span className={cn('adjuster')}>{total}</span>
          </Headline1>
        </div>
        <div className={cn('info')}>
          <BodyCopy>{info}</BodyCopy>
        </div>
      </div>
    </ViewportEnter>
  )
}
Item.propTypes = {
  logo: PropTypes.object,
  total: PropTypes.number,
  info: PropTypes.string,
}

export default Item
