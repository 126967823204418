import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import AspectRatio from 'components/ui/AspectRatio'
// import Image from 'components/ui/Image'
import { BodyCopy, Subtitle, Headline4 } from 'components/ui/Typography'

import s from './TeamSection.module.scss'
const cn = classNames.bind(s)

const Item = ({ image, name, position, brief, callback, shape }) => {
  const ratio = parseFloat(image?.childImageSharp?.fluid.aspectRatio.toFixed(1)) || 1
  const hasContent = name !== '' || position !== '' || brief !== ''

  const onMouseLeave = () => {
    hasContent && callback()
  }

  return (
    <div className={cn('item')} onMouseLeave={onMouseLeave}>
      <div className={cn('image')}>
        <AspectRatio ratio={ratio}>
          {/* {console.log('to do: debug scroll performance issues with gatsby image')} */}
          <img src={image.childImageSharp.fluid.src} alt={image.alt || name} />
          {/* <Image preload="eager" src={image} alt={image.alt || name} /> */}
        </AspectRatio>
      </div>
      {hasContent && (
        <div className={cn('contentWrapper')}>
          <div className={cn('shape', shape)} />
          <div className={cn('info')}>
            {name && (
              <div className={cn('name')}>
                <Headline4>{name}</Headline4>
              </div>
            )}
            {position && (
              <div className={cn('position')}>
                <Subtitle as="h6">{position}</Subtitle>
              </div>
            )}
            {brief && (
              <div className={cn('brief')}>
                <BodyCopy>{brief}</BodyCopy>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
Item.propTypes = {
  image: PropTypes.object,
  name: PropTypes.string,
  position: PropTypes.string,
  brief: PropTypes.string,
  callback: PropTypes.func,
  shape: PropTypes.string,
}

export default Item
