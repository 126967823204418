import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import Item from './Item'
import Button from 'components/ui/Button'
import { BodyCopy } from 'components/ui/Typography'
import { Section, Container } from 'components/layout'

import s from './TeamSection.module.scss'
const cn = classNames.bind(s)

const _ = {
  shapes: ['triangle', 'circle', 'rectangle'],
}

const TeamSection = ({ data, cta }) => {
  const [activeShapeIndex, setActiveShapeIndex] = useState(0)

  const updateShapeSequence = () => {
    const index = (activeShapeIndex + 1) % 3
    setActiveShapeIndex(index)
  }

  const resetShapeSequence = () => {
    setActiveShapeIndex(0)
  }

  return (
    <Section>
      <Container>
        <div className={cn('wrapper')} onMouseLeave={resetShapeSequence}>
          {data.map((item, i) => (
            <Item
              key={i}
              image={item.imageSharp}
              name={item.name ? item.name[0].text : ''}
              position={item.position ? item.position[0].text : ''}
              brief={item?.brief || ''}
              callback={updateShapeSequence}
              shape={_.shapes[activeShapeIndex]}
            />
          ))}
          {cta && (
            <div className={cn('item', 'cta')}>
              <BodyCopy className={cn('link')}>
                <Button to={cta.team_link.url}>{cta.team_cta}</Button>
              </BodyCopy>
            </div>
          )}
        </div>
      </Container>
    </Section>
  )
}

TeamSection.propTypes = {
  data: PropTypes.array,
  cta: PropTypes.object,
}

export default TeamSection
