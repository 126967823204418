import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import { Subtitle, BodyCopy } from 'components/ui/Typography'
import ParallaxImage from 'components/motion/ParallaxImage'

import s from './ImageGridSection.module.scss'
const cn = classNames.bind(s)

const Item = ({ image, text, title }) => {
  return (
    <div className={cn('item')}>
      <ParallaxImage image={image} alt={image.alt || title} />
      <div className={cn('content')}>
        <div className={cn('title')}>
          <Subtitle>{title}</Subtitle>
        </div>
        <div className={cn('text')}>
          <BodyCopy>{text}</BodyCopy>
        </div>
      </div>
    </div>
  )
}

Item.propTypes = {
  image: PropTypes.object,
  text: PropTypes.string,
  title: PropTypes.string,
}

export default Item
