import React from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import { TransitionState } from 'gatsby-plugin-transition-link'

import s from './Counter.module.scss'
const cn = classNames.bind(s)

const leadingZero = (val) => (val < 10 ? `0${val}` : val)

const Counter = ({ progress, total }) => {
  return (
    <TransitionState>
      {({ mount, transitionStatus, current } = {}) => (
        <div className={cn('counter', transitionStatus)}>
          <span className={cn('current')}>{leadingZero(progress)}</span>
          <span className={cn('sep')}>/</span>
          <span className={cn('total')}>{leadingZero(total)}</span>
        </div>
      )}
    </TransitionState>
  )
}

Counter.propTypes = {
  progress: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
}

export default Counter
