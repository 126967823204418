import React, { useState, useEffect } from 'react'
import { useInterval } from 'react-use'
import classNames from 'classnames/bind'
import { motion, AnimatePresence } from 'framer-motion'

import { CURSOR_ICONS } from 'components/ui/Cursor'
import CursorHover from 'components/ui/CursorHover'
import { BodyCopy } from 'components/ui/Typography'
import ViewportEnter from 'components/motion/ViewportEnter'
import Counter from 'components/ui/Counter'
import { Section, Container } from 'components/layout'
import Item from './Item'

import Part1 from 'assets/svg/perspective-1.inline.svg'
import Part2 from 'assets/svg/perspective-2.inline.svg'
import Part3 from 'assets/svg/perspective-3.inline.svg'
import Part4 from 'assets/svg/perspective-4.inline.svg'
import Part5 from 'assets/svg/perspective-5.inline.svg'

import s from './OurPerspectivesSection.module.scss'
const cn = classNames.bind(s)

const _ = {
  items: [
    {
      component: Part1,
      animationClass: 'circles',
      text: `We call it “professional optimism”.`,
    },
    {
      component: Part2,
      animationClass: 'reverse',
      text: `Our ideal partners put humans first, push boundaries, and set their standards high.`,
    },
    {
      component: Part3,
      animationClass: 'color',
      text: `In fact, we believe impact comes from a myriad of voices.`,
    },
    {
      component: Part4,
      animationClass: 'shapes',
      text: `Listening in surround sound gives us ideas we’ve never heard before.`,
    },
    {
      component: Part5,
      animationClass: 'pin',
      text: `Our creative talent network reaches from our backyards to the streets of NYC, Los Angeles, and beyond.`,
    },
  ],
  delay: 4200,
}

const OurPerspectives = () => {
  const [isInView, setIsInView] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [delay, setDelay] = useState(null)

  useInterval(() => isInView && setActiveIndex((activeIndex + 1) % _.items.length), delay)

  const updateIndex = (index) => {
    setDelay(null)
    setActiveIndex(index)
    setTimeout(() => setDelay(_.delay), 0)
  }

  const onPrevClick = () => {
    const index = (activeIndex - 1 < 0 ? _.items.length : activeIndex) - 1
    updateIndex(index)
  }

  const onNextClick = () => {
    const index = (activeIndex + 1) % _.items.length
    updateIndex(index)
  }

  useEffect(() => {
    setDelay(isInView ? _.delay : null)
  }, [isInView])

  return (
    <Section>
      <Container>
        <ViewportEnter onEnter={() => setIsInView(true)} onExit={() => setIsInView(false)} once={false}>
          <div className={cn('wrapper', { isInView })}>
            <div className={cn('view')}>
              <div className={cn('top')}>
                <AnimatePresence exitBeforeEnter={true}>
                  <motion.div
                    key={activeIndex}
                    initial={{
                      opacity: 0,
                      scale: 1.05,
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                      transition: {
                        type: 'tween',
                      },
                    }}
                    exit={{
                      scale: 0.9,
                      opacity: 0,
                      transition: {
                        type: 'tween',
                      },
                    }}
                  >
                    <Item
                      renderAs={_.items[activeIndex].component}
                      animationClass={_.items[activeIndex].animationClass}
                    />
                  </motion.div>
                </AnimatePresence>
              </div>
              <div className={cn('bottom')}>
                <div className={cn('counter')}>
                  <Counter progress={activeIndex + 1} total={_.items.length} />
                </div>
                <div className={cn('text')}>
                  <AnimatePresence exitBeforeEnter={true}>
                    <motion.div
                      key={activeIndex}
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                        transition: {
                          type: 'tween',
                          delay: 0.2,
                          duration: 0.2,
                          ease: 'easeInOut',
                        },
                      }}
                      exit={{
                        opacity: 0,
                      }}
                    >
                      <BodyCopy>{_.items[activeIndex].text}</BodyCopy>
                    </motion.div>
                  </AnimatePresence>
                </div>
              </div>
            </div>
            <CursorHover icon={CURSOR_ICONS.VIDEO_CONTROLS_REWIND}>
              <div className={cn('prev')} onClick={onPrevClick}></div>
            </CursorHover>
            <CursorHover icon={CURSOR_ICONS.VIDEO_CONTROLS_FORWARD}>
              <div className={cn('next')} onClick={onNextClick}></div>
            </CursorHover>
          </div>
        </ViewportEnter>
      </Container>
    </Section>
  )
}

export default OurPerspectives
