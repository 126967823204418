import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import Item from './Item'
import { Section, Container } from 'components/layout'

import s from './ImageGridSection.module.scss'
const cn = classNames.bind(s)

const ImageGrid = ({ data }) => {
  return (
    <Section>
      <Container>
        <div className={cn('wrapper')}>
          {data.map((item, i) => (
            <Item key={i} image={item.imageSharp} text={item.text} title={item.title} alt={item.alt || item.title} />
          ))}
        </div>
      </Container>
    </Section>
  )
}

ImageGrid.propTypes = {
  data: PropTypes.array,
}

export default ImageGrid
